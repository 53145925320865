/*
 * @Author: your name
 * @Date: 2021-04-22 16:18:20
 * @LastEditTime: 2021-08-12 14:42:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \demo\src\router\constantRouter.js
 */
const constantRouter = [
  {
    path: '/',
    redirect: '/preview',
  },
  //预览
  {
    path: '/preview',
    name: 'preview',
    meta: {
      title: '预览',
    },
    component: () => import('@/views/preview'),
  },
  //分享
  {
    path: '/share',
    name: 'share',
    meta: {
      title: '分享',
    },
    component: () => import('@/views/share'),
  },
]

export default constantRouter
