/*
 * @Author: your name
 * @Date: 2021-04-22 16:23:49
 * @LastEditTime: 2021-07-23 13:51:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \demo\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
   
  },
  mutations: {
   
  },
  actions: {
    
    
  },
  getters: {
    
  },
  modules: {},
})
