/*
 * @Author: 庄志莹
 * @Date: 2021-04-22 16:08:29
 * @LastEditTime: 2021-08-06 15:29:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \demo\src\axios\api.js
 */
import http from './http'

export const api = {
  //文件预览
  getImageUrls(params) {
    return http.get('/resource/preview',params)
  },
  //分享课件查看
  queryShareNotes(params) {
    return http.get("/website/oss/getFileList/", params)
  },
}